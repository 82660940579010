<template>
  <lightspeed-layout>
    <div class="lightspeed-show-incident">
      <!-- Back -->
      <div style="margin-bottom: 20px">
        <a-button type="link" style="display: inline; margin-right: 15px" @click.prevent="goBack" icon="left">
          Back to all
        </a-button>
      </div>
      <!-- / Back -->

      <!-- Details Card -->
      <div class="card">
        <div class="header">
          <div class="flex-wrapper">
            <div class="left">
              <h3>Incident Details</h3>
            </div>
            <div class="right" v-if="incident">
              <close-incident-button-and-modal v-if="!isArchived" :incident="incident" :my-presence-id="myPresenceId"
                :tenant-id="tenantId"></close-incident-button-and-modal>
            </div>
          </div>
        </div>
        <div class="body">
          <!-- Loading -->
          <div v-if="isLoading" class="text-center loading-wrapper">
            <a-spin />
          </div>
          <!-- / Loading -->

          <!-- No incident -->
          <div v-if="!isLoading && !incident" class="no-incident-wrapper">
            <a-alert type="error" message="Error loading incident, please try again." />
          </div>
          <!-- / No incident -->

          <!-- Basic Details -->
          <div v-if="!isLoading && incident">
            <div class="page-header">
              <h1 class="page-title">
                {{ incident.displayName }}

                <incident-tags :incident="incident"></incident-tags>
              </h1>
            </div>
          </div>
          <!-- / Basic Details -->
        </div>
      </div>
      <!-- / Details Card -->

      <!-- Row -->
      <a-row :gutter="30" v-if="!isLoading && incident">
        <a-col :span="16">
          <!-- Timeline -->
          <div class="card">
            <div class="header">
              <h3>Messages</h3>
            </div>
            <div class="body body-bb timeline-container">
              <timeline :membership="membership" :owner-type="3" :owner-id="incident.id"
                :timeline="timelineGroupedByDate" :my-presence-id="myPresenceId" :tenant-id="tenantId"
                @view-tasks="viewTasks"></timeline>
            </div>
            <div class="body">
              <typing-indicators :my-presence-id="myPresenceId"></typing-indicators>

              <!-- Doesn't have presence (admin, not archived) -->
              <div class="bottom-toolbar-wrapper" v-if="!amIPresentInGroup && !isArchived">
                <div class="add-me-to-group">
                  <div class="left">
                    <b>You are not a member of this incident...</b>
                  </div>
                  <div class="right">
                    <a-button :disabled="isAddingStaffMembers" @click.prevent="addMeToIncident" type="primary"
                      size="large">
                      Add Yourself To Incident
                    </a-button>
                  </div>
                </div>
              </div>
              <!-- / Doesn't have presence (admin) -->

              <!-- Has Presence (not archived) -->
              <div class="bottom-toolbar-wrapper" v-if="amIPresentInGroup && !isArchived">
                <div class="left">
                  <send-message-actions @add-checklist="addChecklist" @add-staff-members="addStaffMembers"
                    :membership="membership" :tenant-id="tenantId" :org-id="incident.ownerId"
                    :my-presence-id="myPresenceId"></send-message-actions>
                </div>
                <div class="right">
                  <send-message :my-presence-id="myPresenceId"></send-message>
                </div>
              </div>
              <!-- / Has Presence -->

              <!-- Is archived -->
              <div class="bottom-toolbar-wrapper" v-if="isArchived">
                <div class="is-archived" :key="timeAgoRefreshKey">
                  <b>Reason for archiving: </b>{{ incident.archivedNote }}
                  <br />
                  Archived by {{ archivedBy }}
                  {{ displayTimeAgo(incident.archived) }}
                </div>
              </div>
              <!-- / Is archived -->
            </div>
          </div>
          <!-- / Timeline -->
        </a-col>
        <a-col :span="8">
          <div class="rhs-card">
            <div class="body rhs-sidebar">
              <expanded-sidebar></expanded-sidebar>
            </div>
          </div>
        </a-col>
      </a-row>
      <!-- / Row -->
    </div>
  </lightspeed-layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LightspeedLayout from "../LightspeedLayout.vue";
const _ = require("lodash");

import ExpandedSidebar from "../../Incidents/Show/ExpandedSidebar.vue";
import SendMessage from "../../../components/Timelines/SendMessage.vue";
import Timeline from "../../../components/Timelines/Timeline.vue";
import SendMessageActions from "../../../components/SendMessageActions.vue";
import incidents from "../../../api/incidents";
import assignments from "../../../api/assignments";
import IncidentTags from "../../../components/Incidents/IncidentTags.vue";
import TypingIndicators from "../../../components/TypingIndicators.vue";
import CloseIncidentButtonAndModal from './CloseIncidentButtonAndModal.vue';

export default {
  components: {
    LightspeedLayout,
    ExpandedSidebar,
    SendMessage,
    Timeline,
    SendMessageActions,
    IncidentTags,
    TypingIndicators,
    CloseIncidentButtonAndModal
  },

  data() {
    return {
      resellerUpdateKey: 1,

      selectedTab: "messages",
      rightbarCollapsed: true,

      isEditingName: false,
      newName: "",
      isSavingName: false,

      isAddingChecklist: false,
      isAddingStaffMembers: false,

      justCreatedIncident: false,

      notifyArrivalHeartbeatEnabled: false,
    };
  },

  watch: {
    incident: {
      handler(newVal, oldVal) {
        if (newVal) {
          if (!oldVal || oldVal.id !== newVal.id) {
            this.loadGroupMembers(newVal.id);
            this.loadMemberships(newVal.id);
            this.loadResponseTeams();
            this.loadSkills();
            this.loadAssignments();
            this.loadApiClientGroupLinks();
            this.setMetadataIds(newVal.metadataIds ? newVal.metadataIds : []);
            if (!this.isReseller) {
              this.loadTimeline({
                model: "incident",
                tenantId: this.tenantId,
                guid: newVal.id,
                ownerType: 3,
                ownerId: newVal.id,
                organisationId: newVal.ownerId,
                behaviour: 0,
              });
              this.loadQuickMessages();
            }

            if (!this.notifyArrivalHeartbeatEnabled) {
              if (this.amIPresentInGroup) {
                this.notifyArrivalHeartbeatEnabled = true;
                this.notifyArrival();
                this.updateMyLastReadPointer();
              }
            }

            if (!this.isArchived && this.adminMode && !this.amIPresentInGroup) {
              this.subscribeIfAdminMode();
            }
          }
        }
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters("incidentViewer", {
      incident: "incident",
      isLoading: "isLoading",
      myPresenceId: "myPresenceId",
      membership: "membership",
      isWaitingForFirstResponder: "isWaitingForFirstResponder",
      amIPresentInGroup: "amIPresentInGroup",
      isArchived: "isArchived",
      adminMode: "adminMode",
      assignments: "assignments",
    }),

    ...mapGetters("timeline", {
      timelineGroupedByDate: "timelineGroupedByDate",
    }),

    ...mapGetters("identifier", {
      guid: "guid",
    }),

    ...mapGetters("missionControl", {
      isReseller: "isReseller",
    }),

    tenantId() {
      return this.$route.params.tenantId;
    },

    fromAdmin() {
      return this.$route.query.fromAdmin;
    },

    archivedBy() {
      if (this.isArchived && this.membership) {
        let member = _.find(this.membership, { id: this.incident.archivedBy });
        return member ? member.displayName : "";
      }
      return null;
    },

    tasksTabTitle() {
      let t = "Tasks";

      let incompleteTasks = _.filter(this.assignments, (task) => {
        return (
          task["content"]["$v"]["status"] ==
          assignments.getAssignmentNoneStatusEnumValue()
        );
      });

      if (incompleteTasks.length) {
        t = t + " (" + incompleteTasks.length + ")";
      }

      return t;
    },
  },

  methods: {
    ...mapActions("incidents", {
      loadGroupMembers: "loadGroupMembers",
      loadMemberships: "loadMemberships",
      updateIncidentGroupMember: "updateIncidentGroupMember",
    }),

    ...mapActions("incidentViewer", {
      enableAdminMode: "enableAdminMode",
      disableAdminMode: "disableAdminMode",
      loadIncident: "loadIncident",
      handleParticipantAdded: "handleParticipantAdded",
      loadResponseTeams: "loadResponseTeams",
      loadApiClientGroupLinks: "loadApiClientGroupLinks",
      loadAssignments: "loadAssignments",
      loadSkills: "loadSkills",
    }),

    ...mapActions("metadata", {
      setMetadataIds: "setMetadataIds",
      addGuidToMetadata: "addGuidToMetadata",
    }),

    ...mapActions("tasks", {
      loadTasks: "loadTasks",
    }),

    ...mapActions("timeline", {
      loadTimeline: "loadTimeline",
      loadQuickMessages: "loadQuickMessages",
    }),

    incrementResellerUpdateKey() {
      let vm = this;
      window.setTimeout(function () {
        vm.resellerUpdateKey += 1;
      }, 1000);
    },

    expandRightSidebar() {
      this.rightbarCollapsed = false;
    },

    collapseRightSidebar() {
      this.rightbarCollapsed = true;
    },

    listenToSignalRControlMessage() {
      let vm = this;
      this.$signalR.on("ControlMessage", (controlMessage) => {
        console.log("Control Message:");
        console.log(controlMessage);

        let messageBody = JSON.parse(controlMessage["messageBody"]);
        // let groupId = messageBody["groupId"];
        let presenceId = messageBody["presenceId"];

        // if (groupId !== this.incident.id) {
        // return false
        // }

        // Set presence active
        if (controlMessage.messageType === 3) {
          this.setPresenceActive(presenceId);
        }

        // Set presence inactive
        if (controlMessage.messageType === 4) {
          this.setPresenceInactive(presenceId);
        }

        // Update group member
        if (controlMessage.messageType === 13) {
          let groupMemberDetails = messageBody.groupMemberDetails;
          if (groupMemberDetails.groupId === this.incident.id) {
            this.updateIncidentGroupMember(groupMemberDetails);
          }
        }

        // Closed
        if (controlMessage.messageType === 6) {
          if (controlMessage.targetGroupId === this.incident.id) {
            vm.$message.info("Incident has been closed");
            vm.$router.push("/incidents");
          }
        }
      });
    },

    listenToSignalRParticipantAddedMessage() {
      this.$signalR.on("AddParticiant", (participantAddedMessage) => {
        console.log("Participant Added");
        console.log(participantAddedMessage);

        let tenantId = participantAddedMessage.tenantId;
        let groupMemberDetails = participantAddedMessage.groupMemberDetails;

        let groupId = groupMemberDetails.groupId;
        if (tenantId === this.tenantId && groupId === this.incident.id) {
          this.handleParticipantAdded(groupMemberDetails);
        }
      });
    },

    addChecklist(checklist) {
      let vm = this;
      vm.isAddingChecklist = true;
      incidents
        .addChecklistToIncident(
          this.tenantId,
          this.incident.id,
          this.myPresenceId,
          checklist.id
        )
        .then(() => {
          vm.isAddingChecklist = false;
          vm.$message.success("Playbook added successfully");
          vm.addGuidToMetadata(checklist.id);
          vm.loadTasks();
        })
        .catch((e) => {
          console.log(e);
          vm.isAddingChecklist = false;
          vm.$message.error("Error adding checklist to incident");
        });
    },

    addMeToIncident() {
      if (this.isAddingStaffMembers) {
        return false;
      }
      this.addStaffMembers([this.myPresenceId], true);
    },

    addStaffMembers(staffMemberIds, addingMe = false) {
      let vm = this;
      vm.isAddingStaffMembers = true;
      incidents
        .addMembersToIncident(
          this.tenantId,
          this.incident.id,
          staffMemberIds,
          this.myPresenceId
        )
        .then(() => {
          vm.isAddingStaffMembers = false;
          vm.$message.success("Staff members added successfully");
          this.$nextTick(() => {
            this.selectedTab = "messages";
            if (addingMe) {
              this.disableAdminMode();
            }
            if (vm.isReseller) {
              vm.selectedTab = "reseller-overview";
              vm.loadGroupMembers(vm.incident.id);
              vm.loadMemberships(vm.incident.id);
              vm.loadResponseTeams();
              vm.incrementResellerUpdateKey();
            }
          });
        })
        .catch((e) => {
          console.log(e);
          vm.isAddingStaffMembers = false;
          vm.$message.error("Error adding staff members to incident");
        });
    },

    viewTasks() {
      this.selectedTab = "tasks";
    },

    handleResponseTeamAdded() {
      let vm = this;
      this.$nextTick(() => {
        this.selectedTab = "messages";
        if (this.isReseller) {
          vm.selectedTab = "reseller-overview";
          vm.loadGroupMembers(vm.incident.id);
          vm.loadMemberships(vm.incident.id);
          vm.loadResponseTeams();
          vm.incrementResellerUpdateKey();
        }
      });
    },

    async notifyArrival() {
      if (!this.notifyArrivalHeartbeatEnabled) {
        return false;
      }
      if (this.$route.name !== "View Incident") {
        this.notifyArrivalHeartbeatEnabled = false;
        return false;
      }
      try {
        let params = {
          InstallationId: this.guid,
          CurrentTenantId: this.tenantId,
          CurrentGroupId: this.incident.id,
          CurrentPresenceId: this.myPresenceId,
        };
        console.log("Sending NotifyClientAvailable heartbeat:");
        console.log(params);

        await this.$signalR.invoke("NotifyClientAvailable", params);
        console.log("Heartbeat sent successfully");
      } catch (err) {
        console.log("Error notifying arrived");
        console.log(err);
      }

      // Let's trigger another heartbeat in 60 seconds
      let vm = this;
      window.setTimeout(function () {
        vm.notifyArrival();
      }, 60000);
    },

    async notifyDepart() {
      try {
        let params = {
          InstallationId: this.guid,
          CurrentTenantId: null,
          CurrentGroupId: null,
          CurrentPresenceId: null,
        };
        console.log("Sending NotifyClientAvailable departure:");
        console.log(params);

        await this.$signalR.invoke("NotifyClientAvailable", params);
        console.log("Depart message sent successfully");
      } catch (err) {
        console.log("Error notifying departed");
        console.log(err);
      }
    },

    updateMyLastReadPointer() {
      let vm = this;
      this.updateIncidentGroupMember({
        presenceId: vm.myPresenceId,
        groupId: vm.incident.id,
        groupMemberDetails: {
          lastReadPointer: window.moment(),
        },
      });
      this.updateServerLastReadPointer(
        vm.tenantId,
        vm.myPresenceId,
        vm.incident.id
      );
    },

    subscribeIfAdminMode() {
      // TODO: add admin mode if statement (& not a member)
      try {
        this.$signalR.invoke("AddIncidentObserver", {
          TenantId: this.tenantId,
          GroupId: this.$route.params.id,
        });
      } catch (err) {
        console.log(err);
      }
    },

    unsubscribeIfAdminMode() {
      // TODO: add admin mode if statement (& not a member)
      try {
        this.$signalR.invoke("RemoveObserver", {
          TenantId: this.tenantId,
          GroupId: this.$route.params.id,
        });
      } catch (err) {
        console.log(err);
      }
    },

    goBack() {
      this.$router.push("/lightspeed/incidents");
    },
  },

  created() {
    let idFromUrl = this.$route.params.id;
    let isThisIncidentLoaded = this.incident && this.incident.id === idFromUrl;
    if (!isThisIncidentLoaded) {
      this.loadIncident({
        tenantId: this.tenantId,
        id: idFromUrl,
      });
    }
  },
};
</script>

<style scoped lang="scss">
.lightspeed-show-incident {
  padding-bottom: 20px;
}

.card {
  margin-bottom: 20px;
}

.bottom-toolbar-wrapper {
  display: flex;

  .is-archived {
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 8px rgb(0 0 0 / 4%);
    flex: 1;
  }

  .add-me-to-group {
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    box-shadow: 0 0 8px rgb(0 0 0 / 4%);
    flex: 1;

    .left {
      flex-grow: 1;

      b {
        font-weight: 500;
      }
    }

    .right {
      flex-shrink: 1;
      padding-left: 15px;
      text-align: right;
    }
  }

  .left {
    flex-shrink: 1;
    padding-right: 15px;
    display: flex;
    align-items: center;
  }

  .right {
    flex-grow: 1;
  }
}

.timeline-container {
  max-height: 400px;
  overflow-y: scroll;
}

.incident-tags-wrapper {
  margin-left: 20px;
}

.page-header {
  margin-bottom: 0;

  h1 {
    margin-bottom: 8px;
  }
}

.rhs-card {
  border: 1px solid #ebedef;
  border-radius: 2px;
  overflow: hidden;

  .body {
    background: #fff;
    padding: 5px;

    &.bb {
      border-bottom: 1px solid #ebedef;
    }
  }
}


.flex-wrapper {
  display: flex;
  width: 100%;

  .left {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .right {
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }
}
</style>